import React from "react"
import styled from "styled-components"
import { Box } from "../components/Core"
import PageWrapper from "../components/PageWrapper"
import Seo from "../components/Seo"
import Hero2 from "../sections/marketing/Hero2"
import HeroForm from "../sections/marketing/HeroForm"
import WhatWeHaveForYou from "../sections/marketing/WhatWeHaveForU"
import { device } from "../utils"

const StyledPageWrapper = styled.div`
  @media ${device.md} {
    background: none;
  }
`

const CircleDecorator = styled.div`
  width: 37.5rem;
  height: 37.5rem;
  border-radius: 300px;
  background: #dbf2fc;
  position: absolute;
  z-index: -99;
  transform: translateX(-20rem) translateY(-14rem);
  @media ${device.xs} {
    display: none;
  }
  @media ${device.md} {
    display: block;
  }
`

const MarketingPage = () => {
  return (
    <>
      <StyledPageWrapper>
        <PageWrapper footerDark headerBlue>
          <Seo title="Comienza ahora" />
          <HeroForm />
          <CircleDecorator />
          <Hero2 />
          <Box pt="1rem"></Box>
          <WhatWeHaveForYou />
        </PageWrapper>
      </StyledPageWrapper>
    </>
  )
}
export default MarketingPage
